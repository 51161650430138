import React, { useContext, useEffect } from "react";

import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

import {Chatgrupo} from "../Chatgrupo";
import {Dashboard} from "../Dashboard";
import {ChooseConsulta} from "../ChooseConsulta";
import {HistorialConsulta} from "../HistorialConsulta";
import {PaquetesConsulta} from "../PaquetesConsulta";
import Instructivos from "../Instructivos";
import {Faqs} from "../Faqs";
import {CursosActivos} from "../CursosActivos";
import {CursosCompletados} from "../CursosCompletados";
import {Notificaciones} from "../Notificaciones";
import {ModifyProfile} from "../ModifyProfile";
import {General} from "../General";
import { Tracking } from "../Tracking";
import {Consulta} from "../Consulta";
import {DetalleClub} from "../DetalleClub";
import {Comprar} from "../Comprar";
import {PayOrder} from "../PayOrder";
import {PayRequest} from "../PayRequest";
import {Extender} from "../Extender";
import {Login} from "../Login";
import {ForgotPassword} from "../ForgotPassword";
import {ContentDetail} from "../ContentDetail";
import Layout from '../Layout'
import GeneralContext from "../../utils/generalContext";
import BugsnagPerformance from '@bugsnag/browser-performance';
import { ReactRouterRoutingProvider } from '@bugsnag/react-router-performance';
import packageJson from './../../../package.json';

const getViewsTypes = () => {
    return {
        '1': {
            id: 1,
            path: '/chat/:id',
            element: <Layout><Chatgrupo /></Layout>
        },
        '3': {
            id: 3,
            path: '/escoger-consulta',
            element: <Layout><ChooseConsulta /></Layout>
        },
        '4': {
            id: 4,
            path: '/historial-de-consultas',
            element: <Layout><HistorialConsulta /></Layout>
        },
        '5': {
            id: 5,
            path: '/paquetes-de-consulta',
            element: <Layout><PaquetesConsulta /></Layout>
        },
        '6': {
            id: 6,
            path: '/instructivos',
            element: <Layout><Instructivos /></Layout>
        },
        '7': {
            id: 7,
            path: '/preguntas-frecuentes',
            element: <Layout><Faqs /></Layout>
        },
        '8': {
            id: 8,
            path: '/cursos-activos',
            element: <Layout><CursosActivos /></Layout>
        },
        '9': {
            id: 9,
            path: '/cursos-completados',
            element: <Layout><CursosCompletados /></Layout>
        },
        '10': {
            id: 10,
            path: '/notificaciones',
            element: <Layout><Notificaciones /></Layout>
        },
        '11': {
            id: 11,
            path: '/modificar-perfil',
            element: <Layout><ModifyProfile /></Layout>
        },
        '12': {
            id: 12,
            path: '/resumen/:id',
            element: <Layout><General /></Layout>
        },
        '13': {
            id: 13,
            path: '/tracking/:id',
            element: <Layout><Tracking /></Layout>
        },
        '14': {
            id: 14,
            path: '/consulta/:cursoid/:id',
            element: <Layout><Consulta /></Layout>
        },
        '15': {
            id: 15,
            path: '/consulta/:cursoid',
            element: <Layout><Consulta /></Layout>
        },
        '16': {
            id: 16,
            path: '/detalle-club/:id',
            element: <Layout><DetalleClub /></Layout>
        },
        '17': {
            id: 17,
            path: '/comprar/:id',
            element: <Layout><Comprar /></Layout>
        },
        '18': {
            id: 18,
            path: '/pagar-orden/:id',
            element: <Layout><PayOrder /></Layout>
        },
        '19': {
            id: 19,
            path: '/solicitud-de-pago',
            element: <Layout><PayRequest /></Layout>
        },
        '20': {
            id: 20,
            path: '/extender/:id',
            element: <Layout><Extender /></Layout>
        },
        'default': {
            id: 2,
            path: '*',
            element: <Layout><Dashboard /></Layout>
        },
    }
}

const getViewsTypesLogout = () => {
    return {
        '1': {
            id: 1,
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        '2': {
            id: 2,
            path: '/:alias/:id',
            element: <ContentDetail />
        },
        'default': {
            id: 3,
            path: '*',
            element: <Login />
        },
    }
}

export default () => {
    const { user } = useContext(GeneralContext);
    const routes = Object.values(getViewsTypes());
    const routesLogout = Object.values(getViewsTypesLogout());
    const router = createBrowserRouter(routes);
    const routerLogout = createBrowserRouter(routesLogout);

    useEffect(() => {
        BugsnagPerformance.start({
            apiKey: '7b42ed2a4c57b9b2b2e1730436a34139',
            appVersion: packageJson.version,
            routingProvider: new ReactRouterRoutingProvider((user?.user ? routes : routesLogout), '/'),
            releaseStage: 'production'
        })
    }, [])

    return user?.user ? <RouterProvider router={router} /> : <RouterProvider router={routerLogout} />
}
