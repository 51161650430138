
import React, { useContext, useState } from "react";
import Loader from '../Components/Loader'

import logo from "../logo.png";
import GeneralContext from "../utils/generalContext";
import packageJson from '../../package.json';
import axios from "axios";
import SidePanelHome from "../Components/Shared/SidePanelHome";

export const ForgotPassword = () => {
    const { global } = useContext(GeneralContext);
    const [ email, setEmail ] = useState('');
    const [ error, setError ] = useState(null);
    const [ info, setInfo ] = useState(null);
    const [ loader, setLoader ] = useState(false);

    const resetPass = async() => {
        setError(null)
        setInfo(null)
        setLoader(true)
        let url=global.domain+"v3-api/v3/resetPassword.json";
        const result = await axios.post(url, {
            email
          }).catch(function (error) {
            setError(error.message)
            console.error(error.message)
            setLoader(false)
          });
          result.data === null && setError('Esta cuenta de correo no está en nuestra base de datos.')
          result.data !== null && setInfo('Un correo con las instrucciones ha sido enviado.')
          
        setLoader(false)
    }

    return (
        <SidePanelHome>
            <h4 className="my-5 signin-title">Olvidaste tu contraseña?</h4>
            {error && <div className={'alert alert-danger'}>{error}</div>}
            {info && <div className={'alert alert-success'}>{info}</div>}
            <div className="form-group">
                <input 
                type="text" 
                className="form-control" 
                onChange={
                    (e) => setEmail(e.target.value)
                }
                name="email" 
                placeholder="Escriba su cuenta de correo" 
                value={email} 
                />
            </div>
            <div className="form-group d-flex mg-b-0">
                    {loader ? 
                        <Loader />
                        :
                        <button
                            onClick={
                                ()=>resetPass()
                            }
                            className="btn btn-brand-01 btn-uppercase flex-fill">
                                Restablecer contraseña
                        </button>
                    }
                    
            </div>
            <a className="mg-t-auto mg-b-0 tx-md tx-color-03 my-3" href='/'>Ir al login</a>
        </SidePanelHome>
    )
}

