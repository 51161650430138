import React, { useContext, useEffect, useState} from "react"
import Loader from './../Components/Loader'

import { Row, Col } from "react-bootstrap"
import DateFormatted from "../Components/DateFormatted";
import {
    fetching,
    Cardstructure,
    Moneyformat,
    post,
    sendTrack
} from "../Generals"
import GeneralContext, { VerifyContractContext } from "../utils/generalContext";

export const PayRequest = () => {
    const [content , setContent]=useState(null)
    const { global, user, contract, main } = useContext(GeneralContext)
    const verifyContract = useContext(VerifyContractContext);
    const [orderResult , setOrderResult]=useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentInfo , setPaymentInfo]=useState(null)
    const [submitting] = useState(false)
    const userid = user.user.user.id_alumno
    const loadContent = () => {
        let institutoName=user.user.user.INSTITUTO
        let params = {
            no_matricula : user.user.user.no_de_matricula,
            instituto : institutoName
        }
        let url=global.domain+"api/v1/paymentRequestData/"+global.apicode+'/'
        fetching(url,params,setContent,null)
    }

    const processPayment = async () => {
        let orderDetail = {
            productid : 2,
            qty : 1,
            facturaelectronica : 0,
            total : contract.Mensualidadfija,
            userid,
            formadepago : paymentMethod
        }

        let url = global.domain+"api/v1/processOrder/"+global.apicode+'/';
        await post(url, orderDetail, setOrderResult);
    }

    let title = null
    // TODO 
    if(user){
        const vencido = verifyContract?.contratoVencido();
        const proximoVencer = verifyContract?.contratoProximoVencer();
        if(vencido){
            title='Tiene saldo pendiente. <small>Para continuar utilizando nuestros servicios, favor ponerse al día.</small>';
        }else if(proximoVencer){
            title='Tiene un saldo próximo a vencer.<br/><small>No suspenda el uso de su servicio puede pagar con tiempo ahora.</small>';
        }}

    useEffect(() => {
        if(content){
            let firstPaymentMethod = content.getPayments[0];
            setPaymentMethod(firstPaymentMethod?.id);
        }
    },
        [content]
    );

    useEffect(() => {
        async function fetchData() {
            let url = global.domain+ "v3-api/v3/processpayment.json";
            orderResult && await post(url, {orderid: orderResult.result.order.id}, setPaymentInfo);
        }
        fetchData();
      }, [orderResult]); 

    useEffect(() => {
        async function fetchData() {
            if(paymentInfo?.link){
                window.location.href = global.domain+paymentInfo.link
            }
        }
        fetchData();
    }, [paymentInfo]);


    useEffect( () => {
        loadContent()
        sendTrack(userid, window.location.pathname, window.location.hostname, global)

    }, [])
    return <> {!content || !contract || !user
        ?
            <Loader/>
        :
        <Row className={'justify-content-center my-5'}>
            <Col lg={6} md={8} sm={12}>
                <Cardstructure>
                    <h5 dangerouslySetInnerHTML={{__html:title}}></h5>
                    <div className="p-3 border-light bg-light">
                        <span className="text-muted font-weight-bold">
                            Saldo actual: <Moneyformat
                                currency={main.currency}
                                value={contract.Saldoactual}
                            />
                        </span>
                        <br/>
                        <span
                            style={{fontSize: "150%"}}
                            className="text-muted font-weight-bold">
                            Pago pendiente: <Moneyformat
                                currency={main.currency}
                                value={contract.Mensualidadfija}
                            />
                        </span>
                        <br/>
                        <span className="text-danger font-weight-bold">
                            Fecha de vencimiento: <DateFormatted value={contract.Proxvencimiento}/>
                        </span>
                        <br/>
                        {user.user.contract.days_diff > 5
                            ?
                                <div>
                                    <span
                                        className="text-danger font-weight-bold">
                                        Dias de atraso: {user.user.contract.days_diff}
                                    </span>
                                    <br/>
                                    <span
                                        className="text-danger font-weight-bold">
                                        Intereses por atraso: <Moneyformat
                                            currency={main.currency}
                                            value={user.user.contract.atraso}
                                        />
                                    </span>
                                    <br/>
                                </div>
                            :
                            null
                        }

                        <span style={{fontSize: "150%"}} className="text-success font-weight-bold">
                            Total a pagar ahora: <Moneyformat
                            currency={main.currency}
                            value={user.user?.contract?.total_pay}
                            />
                        </span>
                        {content.getPayments.length > 0 ? (
                            <>
                                <select className={"form-control"} onChange={(e)=>setPaymentMethod(e.target.value)}>
                                    {content.getPayments.map(item=><option key={'optionsmet'+item.id} value={item.id}>{item.name}</option>)}
                                </select>

                                <button onClick={processPayment}
                                        disabled={submitting ? true : false}
                                        className="btn btn-block btn-lg mt-3 btn-success">
                                        {submitting ? <Loader /> : "Procesar pago"}
                                </button>
                                { user.user.contract.proximo_vencer && <button
                                    onClick={() => location.href = '/escritorio'}
                                    className="btn btn-block btn-lg mt-3 btn-light">
                                    Volver
                                </button> }
                            </>
                        ) : (
                            <div className="my-3 alert alert-danger">
                                <p>No se puede hacer pagos en línea en estos momentos. Favor comunicarse con su franquiciatario.</p>
                            </div>
                        )}    
                    </div>

                </Cardstructure>
            </Col>
        </Row>
        }
    </>

}

